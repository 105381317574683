import { ComponentType } from 'react';

import { Address } from 'viem';
import { bsc, mainnet } from 'viem/chains';

import { WalletType } from '@api';

import EvmLight from '@uikit/icons/EvmLight';
import { Metamask } from '@uikit/icons/Metamask';
import Okx from '@uikit/icons/Okx';
import Phantom from '@uikit/icons/Phantom';
import { Solana } from '@uikit/icons/Solana';
import { TrustWallet } from '@uikit/icons/TrustWallet';
import WalletConnect from '@uikit/icons/WalletConnect';

import { WagmiConfigChain } from '@shared/common/providers/Web3Provider/wagmi';
import { Passkey } from '@shared/constants/icons/Passkey';

import {
  DAOFarm__factory,
  GasStationUsdtForward__factory,
  LaunchpadFCFSDepositV3__factory,
  LaunchpadFCFSDepositV4__factory,
  LaunchpadProRataDepositV3__factory,
  LaunchpadProRataDepositV4__factory,
  LaunchpadVestingV1__factory,
  LaunchpadVestingV2__factory,
  SQRClaim__factory,
  SQRLockup__factory,
  SQRStaking__factory,
  SQRToken__factory,
} from '../config/contracts/abi-typed';
import { getProcessEnvGlobalConfig } from '../config/global';

export const WALLET_CONNECT_PROJECT_ID =
  typeof process !== 'undefined' ? process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID ?? '' : '';

export interface ContractInfo<TAbi> {
  address: Address;
  abi: TAbi;
}

export const GAS_STATION_FROWARDER_CONTRACT = '0x89C2dC7b0E1a2f2Ff6D3484aFFB8746E74C1D31e';

export const USDT_CONTARACT_BSC = '0x55d398326f99059ff775485246999027b3197955';

const USDT_DEPOSIT_CONTRACT_ADDRESS: Address = '0x5D27C778759e078BBe6D11A6cd802E41459Fe852';

const LAUNCHPAD_PRO_RATA_CONTRACT_ADDRESS: Address = '0xF9d6f73c0bDCd0F2a7734bDCE3aFb9b9030d69F0';

const LAUNCHPAD_VESTING_CONTRACT_ADDRESS: Address = '0xaDa5Df89C7275a021D5174453d55c56F5727308B';

export const DAO_FARM_CONTRACT_ADDRESS: Address = '0x4E76bA2b98B2847F02Cc83F8F7f171cb628aca91';

export type TokenName = 'SQR' | 'tSQR' | 'tSQR2' | 'USDT';

export const tokensInfo: Record<
  TokenName,
  {
    decimals: number;
    symbol: string;
    contractsAddresses: {
      token: Address;
      claim: Address;
      lockup: Address;
      vesting: Address;
      depositLaunchpad?: Address;
    };
  }
> = {
  SQR: {
    decimals: 8,
    symbol: 'SQR',
    contractsAddresses: {
      token: '0x2B72867c32CF673F7b02d208B26889fEd353B1f8',
      claim: '0x1BDd0f4860c7aC88264A9e596da776ACbf2aEBe4',
      lockup: '0x282f8613996343F25FE01f223AB34E6348a2260b',
      vesting: '0x000',
    },
  },
  tSQR: {
    decimals: 8,
    symbol: 'tSQR',
    contractsAddresses: {
      token: '0x4072b57e9B3dA8eEB9F8998b69C868E9a1698E54',
      claim: '0xD06f94D975C68e989F95Be6ca31C98448b78f250',
      lockup: '0xCE3B34160D2D2Dc391963D392AF2808df0ad6c44',
      vesting: '0x000',
    },
  },
  tSQR2: {
    decimals: 8,
    symbol: 'tSQR2',
    contractsAddresses: {
      token: '0x8364a68c32E581332b962D88CdC8dBe8b3e0EE9c',
      claim: '0xD06f94D975C68e989F95Be6ca31C98448b78f250',
      lockup: '0x000',
      vesting: '0xe8af2acde4047f25bcfd4b42bb3a3e687fe2dd42',
    },
  },
  USDT: {
    decimals: 18,
    symbol: 'USDT',
    contractsAddresses: {
      token: '0x55d398326f99059fF775485246999027B3197955',
      claim: '0x000',
      lockup: '0x000',
      vesting: '0x000',
    },
  },
};

const isDev = getProcessEnvGlobalConfig('isDev');

const tokenName: TokenName = isDev ? 'tSQR' : 'SQR';
const stakingTokenName: TokenName = isDev ? 'tSQR2' : 'SQR';
const launchpadTokenName: TokenName = isDev ? 'tSQR2' : 'USDT';

const token = tokensInfo[tokenName];
const stakingToken = tokensInfo[stakingTokenName];

export const launchpadToken = tokensInfo[launchpadTokenName];

// Created to simplify ABI Type apply. No need to use typeof _factory now
const initContract = <TAbi>({
  address,
  abi,
}: {
  address: Address;
  abi: TAbi;
}): ContractInfo<TAbi> => ({
  address,
  abi,
});

export const SQR_CLAIM_CONTRACT = initContract({
  address: token.contractsAddresses.claim,
  abi: SQRClaim__factory.abi,
});

export const SQR_TOKEN_CONTRACT = initContract({
  address: token.contractsAddresses.token,
  abi: SQRToken__factory.abi,
});

export const SQR_LOCKUP_CONTRACT = initContract({
  address: token.contractsAddresses.lockup,
  abi: SQRLockup__factory.abi,
});

export const SQR_STAKING_CONTRACT = initContract({
  address: '0x',
  abi: SQRStaking__factory.abi,
});

export const LAUNCHPAD_VESTING_V1_CONTRACT = initContract({
  address: LAUNCHPAD_VESTING_CONTRACT_ADDRESS,
  abi: LaunchpadVestingV1__factory.abi,
});

export const LAUNCHPAD_VESTING_V2_CONTRACT = initContract({
  address: LAUNCHPAD_VESTING_CONTRACT_ADDRESS,
  abi: LaunchpadVestingV2__factory.abi,
});

export const DAO_FARM_CONTRACT = initContract({
  address: DAO_FARM_CONTRACT_ADDRESS,
  abi: DAOFarm__factory.abi,
});

export const GAS_STATION_USDT_CONTRACT = initContract({
  address: USDT_CONTARACT_BSC,
  abi: GasStationUsdtForward__factory.abi,
});

type ProRataAbi =
  | typeof LaunchpadProRataDepositV3__factory.abi
  | typeof LaunchpadProRataDepositV4__factory.abi;

type FCFSAbi =
  | typeof LaunchpadFCFSDepositV3__factory.abi
  | typeof LaunchpadFCFSDepositV4__factory.abi;

type LaunchpadDepositContract = {
  PRO_RATA: Record<number, ContractInfo<ProRataAbi>>;
  FCFS: Record<number, ContractInfo<FCFSAbi>>;
};

export const LAST_FCFS_CONTRACT_VERSION = 4;

export const LAST_PRO_RATA_CONTRACT_VERSION = 4;

export const LAUNCHPAD_DEPOSIT_CONTRACTS: LaunchpadDepositContract = {
  PRO_RATA: {
    0: initContract({
      address: LAUNCHPAD_PRO_RATA_CONTRACT_ADDRESS,
      abi: LaunchpadProRataDepositV3__factory.abi,
    }),
    1: initContract({
      address: LAUNCHPAD_PRO_RATA_CONTRACT_ADDRESS,
      abi: LaunchpadProRataDepositV3__factory.abi,
    }),
    2: initContract({
      address: LAUNCHPAD_PRO_RATA_CONTRACT_ADDRESS,
      abi: LaunchpadProRataDepositV3__factory.abi,
    }),
    3: initContract({
      address: LAUNCHPAD_PRO_RATA_CONTRACT_ADDRESS,
      abi: LaunchpadProRataDepositV3__factory.abi,
    }),
    4: initContract({
      address: LAUNCHPAD_PRO_RATA_CONTRACT_ADDRESS,
      abi: LaunchpadProRataDepositV4__factory.abi,
    }),
  },
  FCFS: {
    0: initContract({
      address: USDT_DEPOSIT_CONTRACT_ADDRESS,
      abi: LaunchpadFCFSDepositV3__factory.abi,
    }),
    1: initContract({
      address: USDT_DEPOSIT_CONTRACT_ADDRESS,
      abi: LaunchpadFCFSDepositV3__factory.abi,
    }),
    2: initContract({
      address: USDT_DEPOSIT_CONTRACT_ADDRESS,
      abi: LaunchpadFCFSDepositV3__factory.abi,
    }),
    3: initContract({
      address: USDT_DEPOSIT_CONTRACT_ADDRESS,
      abi: LaunchpadFCFSDepositV3__factory.abi,
    }),
    4: initContract({
      address: USDT_DEPOSIT_CONTRACT_ADDRESS,
      abi: LaunchpadFCFSDepositV4__factory.abi,
    }),
  },
};

export const DEFAULT_TARGET_CHAIN: WagmiConfigChain = bsc;

export const MAINNET_CHAIN: WagmiConfigChain = mainnet;

export interface BaseTokenDetails {
  address: Address;
  symbol: string;
  decimals: number;
  chainId: WagmiConfigChain['id'];
  chain: WagmiConfigChain;
}

export const SQR_TOKEN_INFO: BaseTokenDetails = {
  address: token.contractsAddresses.token,
  symbol: token.symbol,
  decimals: token.decimals,
  chainId: bsc.id,
  chain: bsc,
};

export const STAKING_SQR_TOKEN_INFO: BaseTokenDetails = {
  address: stakingToken.contractsAddresses.token,
  symbol: stakingToken.symbol,
  decimals: stakingToken.decimals,
  chainId: bsc.id,
  chain: bsc,
};

export const LAUNCHPAD_TOKEN_INFO: BaseTokenDetails = {
  address: launchpadToken.contractsAddresses.token,
  symbol: launchpadToken.symbol,
  decimals: launchpadToken.decimals,
  chainId: bsc.id,
  chain: bsc,
};

export const walletIconMappings: Record<WalletType, ComponentType> = {
  EVM: EvmLight,
  SOLANA: Solana,
  WalletConnect,
  Phantom,
  Metamask,
  OKX: Okx,
  Trust: TrustWallet,
  Passkey: Passkey,
  WalletConnect2: WalletConnect,
};

export const ADD_WALLET_MESSAGE = 'Confirm adding a wallet to your MagicID';
